<template>
  <v-card class="mx-auto elevation-0" height="100%">
    <v-toolbar class="kalan-titles elevation-0" color="primary--text">
      <v-toolbar-title>
        <span>{{ $vuetify.lang.t(`$vuetify.${model}.plural_name`) }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="margin-right: 20px">
        <v-text-field
          flat
          label="Buscar"
          hide-details
          v-model="search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <actions-button :item="{}" :actions="actions"></actions-button>
    </v-toolbar>
    <kalan-list
      :loading="loading"
      :getTitle="getTitle"
      :getSubTitle="getSubTitle"
      :getExtra="getExtra"
      :items="items"
      :search="search"
      :actions="listActions"
    >
    </kalan-list>
  </v-card>
</template>

<script>
import ApiService from "@/util/api.service";
import BaseTypes from "@/models/baseTypes.model";
import Crud from "@/util/crud.service";

export default {
  props: {
    model: {
      default: "baseTypes",
    },
  },
  components: {
    "kalan-list": () => import("@/components/modulo/List.vue"),
    "actions-button": () => import("@/components/modulo/Actions.vue"),
  },
  data() {
    return {
      items: [],
      groups: {},
      loading: true,
      search: "",
      actions: [
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.create`),
          icon: "mdi-plus",
          color: "secondary",
          action: (item) => {
            Crud.create({
              item,
              schema: BaseTypes,
              model: this.model,
              preSave: this.preSave,
              url: "/modules/base-type",
            }).then(this.load);
          },
        },
      ],
      listActions: [
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.update`),
          icon: "mdi-pencil",
          color: "accent",
          action: (item) => {
            Crud.update({
              item,
              schema: BaseTypes,
              model: this.model,
              title: this.getTitle(item),
              preSave: this.preSave,
              url: "/modules/base-type",
            }).then(this.load);
          },
        },
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.read`),
          icon: "mdi-eye",
          color: "secondary",
          action: (item) => {
            Crud.read({
              item,
              schema: BaseTypes,
              model: this.model,
              title: this.getTitle(item),
            });
          },
        },
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.delete`),
          icon: "mdi-delete",
          color: "red",
          action: (item) => {
            Crud.delete({
              id: item.id,
              model: this.model,
              title: this.getTitle(item),
              url: "/modules/base-type",
            }).then(this.load);
          },
        },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    getTitle: (item) => `${item.name}`,
    getSubTitle(item) {
      const name = "Desconocido";
      return `${item.name || name} `;
    },
    getExtra: () => null,
    preSave(item) {
      const it = Object.assign(item, {});
      it.glueProperties = it.glueProperties.map((prop) => prop.name);
      return it;
    },
    load() {
      this.loading = true;
      ApiService({
        url: "/modules/base-type?all=true",
        method: "get",
      })
        .then((resp) => {
          this.items = resp.map((item) => {
            item.glueProperties = item.glueProperties.map((proper) => ({ name: proper }));
            return item;
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

export default {
  id: {
    type: Number,
    visibility: false,
  },
  name: {
    label: "Nombre",
    type: String,
  },
  menuTitle: {
    label: "Titulo",
    type: String,
  },
  baseClassId: {
    label: "Clase Base",
    type: Number,
    optional: true,
    model: "baseClasses",
    url: "/modules/base-class?all=true",
    autocomplete: false,
  },
  parameterLabel: {
    label: "Label",
    type: String,
  },
  mergeable: {
    label: "Fusionable",
    type: Boolean,
  },
  requiredProperties: {
    label: "Propiedades",
    type: Array,
    title: (item) => item.name,
  },
  "requiredProperties.$": {
    type: {
      name: {
        label: "Nombre",
        type: String,
      },
      type: {
        label: "Tipo",
        type: String,
      },
      label: {
        label: "Label",
        type: String,
      },
      value: {
        label: "Value",
        type: String,
      },
    },
  },
  glueProperties: {
    label: "Propiedades glu",
    type: Array,
    title: (item) => item.name,
  },
  "glueProperties.$": {
    type: {
      name: {
        type: String,
        label: "Nombre",
      },
    },
  },
  requiredParameters: {
    label: "Parametros",
    type: Array,
    title: (item) => item.name,
  },
  "requiredParameters.$": {
    type: {
      name: {
        label: "Nombre",
        type: String,
      },
      type: {
        label: "Tipo",
        type: String,
      },
      customizable: {
        label: "Personalizable",
        type: Boolean,
      },
      value: {
        label: "Value",
        type: String,
      },
    },
  },
  createdAt: {
    label: "Registrado",
    type: Date,
    visibility: false,
    optional: true,
  },
  lastUpdate: {
    label: "Ultima Actualizacion",
    type: Date,
    visibility: false,
    optional: true,
  },
  version: {
    label: "Version",
    type: Number,
    visibility: false,
    optional: true,
  },
};
